import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import LinkCard from "../../../components/LinkCard"

const IndexPage = () => {
    return (
        <Layout pageTitle="Events - Power Hour &amp; Summit">
            <Row>
                <Col>
                    <Link to="/events">Back</Link>
                </Col>
            </Row>
            <Row className="mt-5 justify-content-center">
                <LinkCard to="/events/power-hour/logos" title="Logos" />
                <LinkCard to="/events/power-hour/standard-graphics" title="Standard Graphics" />
                <LinkCard to="/events/power-hour/templates" title="Templates" />
                <LinkCard to="/events/power-hour/press-releases" title="Press Releases" />


            </Row>
        </Layout>
    )
}

export default IndexPage
